<template>
  <div>
    <div class="custom-search mb-2">
      <b-row>
        <b-col md="4">
          <label for="lead-type"> Выберите пользователя: </label>
          <b-form-select
            id="lead-type"
            v-model="form.user"
            :options="usersList"
            @change="filterByUser"
          >
          </b-form-select>
        </b-col>
        <!-- <b-col md="4">
          <label for="lead-type"> Выберите даты: </label>
          <flat-pickr
            class="form-control"
            placeholder="..."
            v-model="dateRange"
            :config="configDateRange"
          />
        </b-col> -->
        <b-col md="4">
          <label for="lead-type"> Выберите лид форму: </label>
          <b-form-select
            id="lead-type"
            v-model="form.leadForm"
            :options="leadFormsList"
            @change="filterByLeadForm"
          >
          </b-form-select>
        </b-col>
      </b-row>
    </div>
    <answers-table :answers="getAnswersList" />
  </div>
</template>

<script>
import AnswersTable from "./AnswersTable.vue";
import { mapActions, mapGetters } from "vuex";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import {
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BForm,
  BRow,
  BCol,
} from "bootstrap-vue";

export default {
  components: {
    AnswersTable,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BForm,
    BRow,
    BCol,
    flatPickr,
  },
  computed: {
    ...mapGetters("admin", [
      "getAnswers",
      "getUsers",
      "getLeadForms",
      "getProjects",
    ]),
    getAnswersList() {
      const list = [];
      let answersObj = this.getAnswers.data;
      if (this.$route.query) {
        for (const [key, value] of Object.entries(this.$route.query)) {
          if (key === "user" && value) {
            answersObj = answersObj.filter((answer) => answer.userId === value);
          }
          if (key === "leadForm" && value) {
            answersObj = answersObj.filter(
              (answer) => answer.leadFormId === value
            );
          }
        }
      }
      if (answersObj)
        answersObj.forEach((answerObj) => {
          const ans = {};
          ans.timeAt =
            new Date(answerObj.timeAt.seconds * 1000).toLocaleDateString(
              "ru-RU"
            ) +
            " " +
            answerObj.timeAt.toDate().toLocaleTimeString("ru-RU");
          ans.project = this.findProjectName(answerObj.projectId);
          ans.leadForm = this.findFormName(answerObj.leadFormId);
          ans.displayId = answerObj.displayId;
          ans.user = this.findUserEmail(answerObj.userId);
          for (const [key, value] of Object.entries(answerObj.answers)) {
            ans[this.findQuestionType(answerObj.leadFormId, key)] = value;
          }
          list.push(ans);
        });
      return list;
    },
    leadFormsList() {
      const list = this.getLeadForms.data.map((form) => {
        return {
          value: form.id,
          text: form.name,
        };
      });
      list.push({ value: null, text: "Все" });
      return list;
    },
    usersList() {
      const users = [{ value: null, text: "Все" }];
      for (const [key, user] of Object.entries(this.getUsers.answer)) {
        users.push({ value: key, text: user.email });
      }
      return users;
    },
  },
  data() {
    return {
      form: {},
      dateRange: null,
      configDateRange: {
        minDate: null,
        maxDate: new Date(),
        locale: Russian,
        mode: "range",
      },
      isDateRangeChanged: false,
      initRequest: {
        dateStart: null,
        dateEnd: null,
      },
      answersList: null,
    };
  },
  methods: {
    ...mapActions("admin", [
      "requestUsers",
      "requestAnswers",
      "requestLeadForms",
      "requestProjects",
    ]),
    findFormName(id) {
      const res = this.getLeadForms.data.find((form) => {
        return id === form.id;
      });
      if (res) return res.name;
      else return "-";
    },
    findQuestionName(formId, id) {
      const form = this.getLeadForms.data.find((form) => {
        return formId === form.id;
      });
      const res = form.questions.find((question) => {
        return question.id === id;
      });
      if (res) return res.title;
      else return null;
    },
    findQuestionType(formId, id) {
      const form = this.getLeadForms.data.find((form) => {
        return formId === form.id;
      });
      let res = {};
      if (form)
        res = form.questions.find((question) => {
          return question.id === id;
        });
      else return "-";
      if (res) return res.type;
      else return null;
    },
    findProjectName(projectId) {
      const res = this.getProjects.answer[projectId];
      if (res) return res.name;
      return null;
    },
    findUserEmail(id) {
      console.log(id);
      const res = this.getUsers.answer[id];
      if (res) return res.email;
      return null;
    },
    filterByUser() {
      this.$router.replace({
        query: { ...this.$route.query, user: this.form.user },
      });
    },
    filterByLeadForm() {
      this.$router.replace({
        query: { ...this.$route.query, leadForm: this.form.leadForm },
      });
    },
  },
  created() {
    this.requestUsers();
    this.requestAnswers();
    this.requestLeadForms();
    this.requestProjects();
    if (this.$route.query) {
      this.form = { ...this.$route.query };
    }
  },
};
</script>
