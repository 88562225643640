<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="answers"
      :rtl="direction"
      :bordered="false"
      class="projects-table"
      styleClass="vgt-table"
    >
      <div slot="emptystate" class="text-center">
        Нет заявок
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    VueGoodTable,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: ["answers"],
  data() {
    return {
      dir: false,
      rows: [],
      columns: [
        {
          label: "ID",
          field: "displayId",
        },
        {
          label: "Автор",
          field: "user",
        },
        {
          label: "Дата заявки",
          field: "timeAt",
        },
        {
          label: "Название проекта",
          field: "project",
        },
        {
          label: "Лид-форма",
          field: "leadForm",
        },
        {
          label: "Имя",
          field: "name",
        },
        {
          label: "Телефон",
          field: "phone",
        },
        {
          label: "E-mail",
          field: "email",
        },
        {
          label: "Текстовое поле",
          field: "string",
        },
      ],
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.projects-table tr:hover {
  background-color: #f8f8f8;
}
</style>
